import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
declare var $: any;

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { settings } from 'cluster';
@Injectable()
export class SettingService {

  constructor(private http: HttpClient) { }

   //settings
    createsetting(title,address, logo): Observable<any>  {
      const formData: FormData = new FormData();
      formData.append('title', title);
      formData.append('address', address);
      if (logo instanceof File ) {
        formData.append('logo', logo, logo.name);
      }
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard/addTitle`, formData).pipe(map(settings => {
        return settings;
      }));
    }

     updateSetting(id,title,logo,address): Observable<any>  {
      const formData: FormData = new FormData();
      formData.append('id', id);
      formData.append('title', title);
      formData.append('address', address);
      if (logo instanceof File) {
        formData.append('logo', logo, logo.name);
      } 
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard/addTitle`, formData).pipe(map(settings => {
        return settings;
      }));
    }

    getSettings(): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/getTitle`).pipe(map(settings => {
        return settings;
      }));
    }

 
    deleteSetting(id:any){
      return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard/deleteTitle/` + id).pipe(map(user=> user))
    }

    getDashboardLogo(){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard/getTitle`).pipe(map(settings => {
        return settings;
      }));
    }

}
