import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
declare var $: any;

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }
  //WhiteLotus Project

   getNewbooking(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getbookingdetails`).pipe(map(user => user));
   }

   //Amenities
    createAmenities(name, image): Observable<any>  {

      const formData: FormData = new FormData();
      formData.append('amenities', name);
      if (image) {
        formData.append('amenities_image', image, image.name);
      }
  
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/amenities`, formData).pipe(map(user => {
        return user;
      }));
    }

     updateAmenities(id,name, image): Observable<any>  {

      const formData: FormData = new FormData();
      formData.append('amenities', name);
      if (image) {
        formData.append('amenities_image', image, image.name);
      }
  
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/amenities/`+id, formData).pipe(map(user => {
        return user;
      }));
    }

    getAmenities(page): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/list-amenities?page=`+page).pipe(map(user => {
        return user;
      }));
    }

     deleteAmenities(id: string) {
      return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/amenities/` + id).pipe(map(user => {
         return user;
       }));
    }

    //Add Rate-type

    createRateType(name){
      var formData = {
        'name': name,
      }
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rate/store`, formData).pipe(map(user => {
        return user;
      }));
    }

    updateRateType(id,name){
       var formData = {
        'name': name,
      }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rate/` + id, formData).pipe(map(user => {
      return user;
    }));
    }

    DeleteRate(id: string) {
      return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rate/` + id).pipe(map(user => {
        return user;
      }));
  }


    //Room Price
    getRoomType(): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/room-list`).pipe(map(user => user));
    }

    getRoomPrice(type,date): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-roomtypeprice/`+type+'?selected_date='+date).pipe(map(user => user));
    }

    getAvailableRooms(date):Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/available-rooms?selected_date=`+ date).pipe(map(user => user));
    }

    UpdatePrice(price,date,type) {
      var formData = {
        'room_type': type,
        'amount': price,
        'date':date
      }
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/add_roomprice`, formData).pipe(map(user => {
         return user;
      }));
    }

     patchRoomprice(room_type,amount: number[],selected_date,status){
       var formData = {
        'room_type': room_type,
        'amount': JSON.stringify(amount),
        'selected_date':selected_date,
        'status':status
       } 
       return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/setup-room-price`, formData).pipe(map(user => user));
     }

     roomavialabel(id,rooms,selected_date){
      var formData = {
        'room_type': id,
        'inventory_data': rooms,
        'selected_date':selected_date
      }
       return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/add-inventory`,formData).pipe(map(user => user));
     }

    //Add Room Type

    getRate(page): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rate?page=`+page).pipe(map(user => user));
    }

    listamenities(): Observable<any> {
       return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/list-amenities`).pipe(map(user => user));
    }

   addRoomType(room_type,price,rate_type,amenities,description,
    no_occupancy,single_occupancy,extra_occupancy,images: File[],accomodation_type,packageArr){
    const formData: FormData = new FormData();
    formData.append('room_type', room_type);
    formData.append('price', price);
    formData.append('rate_type', rate_type);
    formData.append('description', description);
    formData.append('no_of_occupancy', no_occupancy);
    formData.append('extra_occupancy', extra_occupancy);
    formData.append('single_occupancy_price', single_occupancy);
    images && images.map(image => {
      formData.append('room_image', image, image.name);
    });
    amenities && amenities.map(amenities => {
      formData.append('amenities', amenities);
    });
    formData.append('accomodation_type',accomodation_type)
    if(packageArr[0].status){
      formData.append('packageArr',JSON.stringify(packageArr))
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rooms-type/store`, formData).pipe(map(user => {
      return user;
    }));
  }

   DeleteRoomtype(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rooms-type/` + id).pipe(map(user => {
      return user;
    }));
  }

   updateRoomType(id,room_type,price,rate_type,amenities,description,
    no_occupancy,single_occupancy,extra_occupancy,images: File[],accomodation_type,packageArr){
    const formData: FormData = new FormData();
    formData.append('room_type', room_type);
    formData.append('price', price);
    formData.append('rate_type', rate_type);
    // formData.append('amenities',amenities);
    formData.append('description', description);
    formData.append('no_of_occupancy', no_occupancy);
    formData.append('extra_occupancy', extra_occupancy);
    formData.append('single_occupancy_price', single_occupancy);
    images && images.map(image => {
      formData.append('room_image', image, image.name);
    });
    amenities && amenities.map(amenities => {
      formData.append('amenities', amenities);
    });
    formData.append('accomodation_type',accomodation_type)
    if(packageArr[0].status){
      formData.append('packageArr',JSON.stringify(packageArr))
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rooms-type/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  
  //Room Master (count)
    getRooms(): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rooms`).pipe(map(user => user));
    }

    saveRooms(room_number: any,room_type: any,) {
    var formData = {
      'total_rooms': room_number,
      'room_type': room_type
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rooms/store`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateRooms(id,room_number: any,room_type: any,) {
    var formData = {
      'total_rooms': room_number,
      'room_type': room_type
    }
   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rooms/store`, formData).pipe(map(user => {
      return user;
    }));
  }

   DeleteRoomCount(id: string) {
      return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/rooms/` + id).pipe(map(user => {
        return user;
      }));
  }

  //Block Rooms
  getBlock(page): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blocks?page=`+page).pipe(map(user => user));
  }

   updateroomBlock(roomtype,roomno: any, fromdate,todate,status: any,uniqueId) {    
    var formData = {
      'room_type':roomtype,
      'total_rooms': roomno,
      'from_date':fromdate,
      'to_date':todate,
      'status':status,
      'uniqueId':uniqueId
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blockmultiplerooms`, formData).pipe(map(user => {
      return user;
    }));
  }

  addroomblock(roomtype,roomno: any, fromdate, todate, status: any) {
    var formData = {
      'room_type':roomtype,
      'total_rooms': roomno,
      'from_date':fromdate,
      'to_date':todate,
      'status':status,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/blockmultiplerooms`, formData).pipe(map(user => {
      return user;
    }));

  }

  //New Booking

  getBookingDetails(page,search,arrival_date,departure_date): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getbookingdetails?page=` +page + '&search=' + search + '&arrival_date=' + arrival_date + '&departure_date=' + departure_date).pipe(map(user => user));
  }

  cancelBooking(id) {
  var formData = {
    'status':13,
    'booking_status':13
  }
  return this.http.patch<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/cancel_status/` + id, formData).pipe(map(user => {
    return user;
  }));
 }

  getGuestHistory(page,search,arrival_date,departure_date){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/guesthistory?page=` + page + '&search=' + search + '&arrival_date=' + arrival_date + '&departure_date=' + departure_date).pipe(map(user => user));
  }

  getCancelledBookings(page,search,arrival_date,departure_date){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/cancelled?page=` + page + '&search=' + search + '&arrival_date=' + arrival_date + '&departure_date=' + departure_date).pipe(map(user => user));
  }



  //dashboard
  getDashboardchk(date: any): Observable<any> {
      var formData = {'selected_date':date}
       return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-checkincount?`+$.param(formData)).pipe(map(user => user));
   }

   userDashboard(selected_date){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard-count?start_date=`+selected_date).pipe(map(user => user));
   }

   dashboardCounts(selected_date){
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard?start_date=`+selected_date).pipe(map(user => user));
   }

  todayCounts(selected_date){
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/today-dashboard?start_date=`+selected_date).pipe(map(user => user));
  }

  yesterdayCounts(selected_date){
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/yesterday-dashboard?start_date=`+selected_date).pipe(map(user => user));
  }

  last7daysCounts(selected_date){
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/last-7-dashboard?start_date=`+selected_date).pipe(map(user => user));
  }

   last30daysCounts(selected_date){
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/last-30-dashboard?start_date=`+selected_date).pipe(map(user => user));
  }

  lastMonthCounts(selected_date){
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/last-month-dashboard?start_date=`+selected_date).pipe(map(user => user));
  }

  getDashboardData(start_date,end_date){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/custom-dashboard?start_date=`+ start_date + '&end_date=' + end_date).pipe(map(user => user));
   }

   // Download Pdf Invoice

   downloadBookingInvoice(id:any){
      const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/bookingdetailsdownload?uniqueid=` + id, { responseType: 'blob' as 'json' }).pipe(map(user => {
        return user
      }));
      return file;
    }
    downloadBookingInvoice1(id:any){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/bookingdetailsdownload?uniqueid=` + id).pipe(map(user => {
        return user
      }));
    }

    downloadPrevBookingInvoice(id:any){
      const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/previousbookingdetailsdownload?uniqueid=` + id, { responseType: 'blob' as 'json' }).pipe(map(user => {
        return user
      }));
      return file;
    }
    downloadPrevBookingInvoice1(id:any){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/previousbookingdetailsdownload?uniqueid=` + id).pipe(map(user => {
        return user
      }));
    }

    downloadCancelledBookingInvoice(id:any){
      const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/cancelledbookingdetailsdownload?uniqueid=`+ id, { responseType: 'blob' as 'json' }).pipe(map(user => {
        return user
      }));
      return file;
    }
    downloadCancelledBookingInvoice1(id:any){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/cancelledbookingdetailsdownload?uniqueid=`+ id).pipe(map(user => {
        return user
      }));
    }

    //download excel sheets
    downloadNewBookExcel(arrival_date:any,departure_date:any){
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/bookingdetailsexceldownload?arrival_date=` + arrival_date + '&departure_date=' + departure_date , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadPrevBookExcel(arrival_date:any,departure_date:any){
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/previousbookingexceldownload?arrival_date=` + arrival_date + '&departure_date=' + departure_date , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadCancelledBookExcel(arrival_date:any,departure_date:any){
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/cancelledbookingexceldownload?arrival_date=` + arrival_date + '&departure_date=' + departure_date , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

  // Room Price - New api
    getRoomlistLatest(arrival_date,departure_date){
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/room-list?arrival_date=`+ arrival_date + '&departure_date=' + departure_date).pipe(map(user => user));
   }

   getRoomPriceLatest(id,arrival_date,departure_date): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-roomtypeprice/`+id + '?arrival_date=' + arrival_date + '&departure_date=' + departure_date).pipe(map(user => user));
    }
}
