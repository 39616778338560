import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
    register(name: string, email: string, password: string, cpassword: any, phoneNumber: any, phone: any, company: any, address1: any, address2: any, country: any, state
        : any, city: any, refrral: any, plansId = "") {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/signup`, { name: name, email: email, password: password, cpassword: cpassword, company: company, phoneNumber: phoneNumber, phone: phone, address1: address1, address2: address2, country: country, state: state, city: city, refrral: refrral, plansId: plansId }).pipe(map(user => {
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', '');
            }
            return user;
        }));
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/login`, { email: email, password: password }).pipe(map(user => {
            //if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.user));
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + 'username', user.data.user.username);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.role_id);
            //}
            return user;
        }));
    }

    logout() {
        localStorage.removeItem(`${environment.appName}` + '_user_obj');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_role');
    }

    public isAuth() {
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            return true;
        } else {
            return false;
        }
    }


}