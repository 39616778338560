export const environment = {
  appName: "Samudyatha Inn & Suites Admin",
  production: false,

  //apiUrl:'https://oasis-devapi.invoicemaster.in/',  //production
  apiUrl: "https://samudyatha-api.invoicemaster.in/", //development
  //media_domain: 'https://d2gvsnjeeksddg.cloudfront.net/production', //production
  media_domain: "https://d2dcenou0y6e7u.cloudfront.net/production", //development
  apiPrefix: "api/v1",
};
